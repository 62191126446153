import gql from 'graphql-tag';

const USER_PROFILE_UPDATE = gql`
  mutation USER_PROFILE_UPDATE($record: UpdateUserProfileInput!) {
    userProfileUpdate(record: $record) {
      recordId
      record {
        __typename
        _id
        profile {
          __typename
          firstName
          lastName
          avatar
          email
          nationality {
            code
            name
          }
          workPermit {
            id
            name
          }
        }
      }
    }
  }
`;

const USER_UPDATE_AVATAR = gql`
  mutation USER_UPDATE_AVATAR($file: Upload!) {
    userAvatarUpdate(file: $file)
  }
`;

const UPDATE_USER_ONBOARDING_STATUS = gql`
  mutation UPDATE_USER_ONBOARDING_STATUS {
    updateUserOnboardingStatus
  }
`;

const LOGIN_USER_WITH_PASSWORD = gql`
  mutation LOGIN_USER_WITH_PASSWORD(
    $username: String!
    $password: String!
    $organizationToken: String
  ) {
    loginUserWithPassword(
      username: $username
      password: $password
      organizationToken: $organizationToken
    ) {
      id
      lastSelectedLanguage
    }
  }
`;

const CREATE_USER_WITH_PASSWORD = gql`
  mutation CREATE_USER_WITH_PASSWORD(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $nationality: CountryInput
    $workPermit: WorkPermitInput
    $organizationToken: String
    $skillsToValidate: [CreateOneUserValidateSkillInput]
    $followedOpportunities: [UserOpportunityInsertInput]
  ) {
    createUserWithPassword(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      nationality: $nationality
      workPermit: $workPermit
      organizationToken: $organizationToken
      skillsToValidate: $skillsToValidate
      followedOpportunities: $followedOpportunities
    )
  }
`;

const REQUEST_RESET_PASSWORD_TOKEN = gql`
  mutation REQUEST_RESET_PASSWORD_TOKEN($email: String!) {
    requestResetPasswordToken(email: $email) {
      expiresIn
    }
  }
`;

const RESET_USER_PASSWORD = gql`
  mutation RESET_USER_PASSWORD($token: String!, $password: String!) {
    resetUserPassword(token: $token, password: $password)
  }
`;

const CHANGE_USER_PASSWORD = gql`
  mutation CHANGE_USER_PASSWORD($oldPassword: String!, $newPassword: String!) {
    changeUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export {
  CHANGE_USER_PASSWORD,
  CREATE_USER_WITH_PASSWORD,
  LOGIN_USER_WITH_PASSWORD,
  REQUEST_RESET_PASSWORD_TOKEN,
  RESET_USER_PASSWORD,
  UPDATE_USER_ONBOARDING_STATUS,
  USER_PROFILE_UPDATE,
  USER_UPDATE_AVATAR,
};
