import React from 'react';

import useConfig from 'UI/customHooks/useConfig';

import styles from './styles.css'; // eslint-disable-line no-unused-vars

class ErrorBoundaryRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: props.error || null };
  }

  static getDerivedStateFromError(error) {
    console.error('React error handler ', error);
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  render() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cdnUrl } = useConfig();

    if (this.state.error) {
      // This page should be styled with "vanilla" CSS to prevent and potential throws in here,
      // which would lead to an error which renders a white blank page.
      return (
        <div className="error_boundary_body">
          <div className="error_boundary_wrapper">
            <div className="error_boundary_content">
              <div className="error_boundary_textWrapper">
                <p className="error_boundary_subtitle">Application not available</p>
                <p className="error_boundary_info">
                  Somebody must have accidentally pulled the plug.
                  <br />
                  <strong>
                    Please <a href="/">try again</a> in few minutes...
                  </strong>
                </p>
              </div>
              <img
                className="error_boundary_image"
                src={`${cdnUrl}/assets/errorPage/Error.svg`}
                alt="Error boundary"
              />
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryRoot;
