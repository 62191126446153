import { HEADER_TENANTID_IDENTITY, headerContext } from 'UI/constants/apiHeaders';
import { navigateInternal } from 'UI/lib';

import getBackend from '../getBackend';
import { AUTH_ERROR_CODE_QUERY_PARAM } from './constants';
import { logoutUser, setBrowserUserCredentials } from './userCredentials';

/** Gets and persists user credentials into localStorage using SSO search params.
 * @typedef {Object} UserCredentials
 * @property {string} userId
 * @property {string} loginToken
 */

/**
 * @return {Promise.<{ ok: boolean, status: number, credentials: UserCredentials}>}
 */
const fetchCredentials = async code => {
  const response = await fetch(`${getBackend()}/auth/sso/login`, {
    method: 'POST',
    body: JSON.stringify({ code }),
    headers: {
      [headerContext[HEADER_TENANTID_IDENTITY].key]: window.rootOrganizationId,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const { status } = response;

  if (status === 200) {
    const res = await response.json();
    const { userId, accessToken } = res;

    return {
      ok: true,
      credentials: {
        userId,
        accessToken,
      },
    };
  }

  return {
    ok: false,
    status,
  };
};

export const loginSsoUser = async ({ code, state }) => {
  const response = await fetchCredentials(code);

  if (response.ok) {
    const { userId: palyxUserId, accessToken: palyxToken } = response.credentials;

    setBrowserUserCredentials({
      palyxUserId,
      palyxToken,
    });
    navigateInternal(decodeURIComponent(state));
    return true;
  }

  return await logoutUser(`/?${AUTH_ERROR_CODE_QUERY_PARAM}=${response.status || 500}`);
};
