import React, { Fragment } from 'react';

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const topSpace = 34;

const styles = theme => ({
  navigationBarGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

const LXPHeaderPlaceholder = ({ classes }) => {
  return (
    <Fragment>
      <Grid
        className={classes.navigationBarGrid}
        height={topSpace * 2}
        width="100%"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 9,
        }}
      />
      <Grid className={classes.navigationBarGrid} mt={topSpace} />
    </Fragment>
  );
};

export default withStyles(styles)(LXPHeaderPlaceholder);
